import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import PageTitleAlt2 from "../../Layout/AppMain/PageTitleAlt2";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ContentModal from "./ContentModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Pagination from 'react-bootstrap/Pagination';

const ViewModule = function () {
  const [module, setModule] = useState({});
  const [content, setContent] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const params = useParams();
  const moduleId = params.id;
  const fields = module?.fields?.filter(field => field.showInTable === true);

  useEffect(() => {
    const getModule = async () => {
      const result = await axios.get(process.env.REACT_APP_API_URL + '/api/modules/' + moduleId);
      setModule(result.data);
    }

    getModule();
  }, [moduleId]);

  const addContent = async (data) => {
    console.log("addContent", data);
    const newContent = [...content, data];
    setContent(newContent);
    setShowModal(false);
  }

  const deleteContent = (index) => {
    const newContent = [...content];
    newContent.splice(index, 1);
    setContent(newContent);
  }

  return (
    <div>
      <PageTitleAlt2 heading="View Module" icon={module?.icon} />
      <Card>
        <CardHeader>
          <h3>{module?.name}</h3>
          <Button className="ms-4" color="primary" onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faAdd} /> Add New
          </Button>
        </CardHeader>
        <CardBody>
          <p>{module?.description}</p>
          <Table responsive striped bordered>
            <thead>
              <tr>
                {fields?.map((field, index) => (
                  <th key={index}>{field?.fieldDisplayName}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {content.map((item, index) => (
                console.log(item),
                <tr key={index}>
                  {fields?.map((field, index) => (
                    <td key={index}>{item[field.fieldVariable]}</td>
                  ))}
                  <td>
                    <Button className="me-2" color="outline-primary" onClick={() => setShowModal(true)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button color="outline-danger" onClick={() => { deleteContent(index) }}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}

            </tbody>
          </Table>

          <Pagination>
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Ellipsis />

            <Pagination.Item>{10}</Pagination.Item>
            <Pagination.Next />
          </Pagination>
        </CardBody>
      </Card>
      <ContentModal
        open={showModal}
        onClose={() => setShowModal(false)}
        content={{}}
        fields={module?.fields || []}
        saveContent={addContent} />
    </div>
  );
};

export default ViewModule;