import React, { Fragment, useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import axios from "axios";

const NavPreview = (props) => {
  const [mainNavContent, setMainNavContent] = useState([]);
  const params = useParams();
  const ComponentsNav = [
    {
      icon: "pe-7s-user",
      label: "Users",
      to: "#/users",
    },
    {
      icon: "pe-7s-umbrella",
      label: "Roles",
      to: "#/projects",
    },
    {
      icon: "pe-7s-key",
      label: "Permissions",
      to: "#/modules",
    },
  ]

  useEffect(() => {
    async function getModules() {
      const project = params['project'];
      console.log("project", project);
      const result = await axios.get(process.env.REACT_APP_API_URL + '/api/modules?project=' + project);
      console.log(result.data);

      const menu = result.data.map((item) => {
        return {
          icon: item.icon,
          label: item.name,
          to: '#/preview/' + project + '/module-view/' + item._id,
        }
      })
      const dashboard = {
        icon: "pe-7s-display2",
        label: "Dashboard",
        to: '#/preview/' + project + '/dashboard',
      }

      setMainNavContent([dashboard, ...menu]);
    }
    getModules();
  }, []);

  const toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Menu</h5>
      <MetisMenu content={mainNavContent} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />

      <h5 className="app-sidebar__heading">Manage</h5>
      <MetisMenu content={ComponentsNav} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />

    </Fragment>
  );

}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavPreview));
