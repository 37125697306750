
export const MainNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'Projects Dashboard',
        to: '#/dashboards/projects',
    },
];
export const ComponentsNav = [
    {
        icon: 'pe-7s-diamond',
        label: 'Modules',
        content: [
            {
                label: 'Crud',
                to: '#/elements/modules',
            },
            {
                label: 'Forms',
                to: '#/elements/utilities',
            },
        ],
    },
    {
        icon: 'pe-7s-car',
        label: 'Components',
        content: [
            {
                label: 'Tabs',
                to: '#/components/tabs',
            },
            {
                label: 'Notifications',
                to: '#/components/notifications',
            },
        ],
    },
    {
        icon: 'pe-7s-display2',
        label: 'Menu Manager',
        to: '#/tables/regular-tables',
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Projects Dashboard',
        to: '#/dashboards/projects',
    }
];