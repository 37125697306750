import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import MinimalDashboard2 from "./Minimal/Variation2";

// Layout

import AppHeader from "../../Layout/AppHeader/";
import PreviewSidebar from "../../Layout/AppSidebar/preview";
import AppFooter from "../../Layout/AppFooter/";

// Theme Options
import ThemeOptions from "../../Layout/ThemeOptions/";
import ViewModule from "../Builder/ViewModule";

const Preview = ({ match }) => (
  <Fragment>
    <ThemeOptions />
    <AppHeader headerBackgroundColor="bg-malibu-beach" />
    <div className="app-main">
      <PreviewSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route path={`${match.url}/dashboard`} component={MinimalDashboard2} />
          <Route path={`${match.url}/list`} component={MinimalDashboard2} />
          <Route path={`${match.url}/module-view/:id`} component={ViewModule} />

          <Route path={`${match.url}/view`} component={MinimalDashboard2} />
        </div>
        <AppFooter />
      </div>
    </div>
  </Fragment>
);

export default Preview;
