import React, { Component, Fragment, useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import {
  MainNav,
  ComponentsNav,
} from "./NavItems";
import axios from "axios";

const Nav = (props) => {
  const [mainNavContent, setMainNavContent] = useState(MainNav);
  const params = useParams();

  useEffect(() => {
    async function getModules() {
      const project = params['project'];
      console.log("project", project);
      const result = await axios.get(process.env.REACT_APP_API_URL + '/api/modules?project=' + project);
      console.log(result.data);
      const menu = result.data.map((item) => {
        return {
          icon: item.icon,
          label: item.name,
          to: '#/elements/module-view/' + item._id,
        }
      })
      setMainNavContent(menu);
    }
    getModules();
  }, []);

  const toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Menu</h5>
      <MetisMenu content={mainNavContent} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />

      <h5 className="app-sidebar__heading">Builder</h5>
      <MetisMenu content={ComponentsNav} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />

    </Fragment>
  );

}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
